import { unescapeHTML } from "@/utilities/word";

import { formatAuthorDetails } from "./author";
import {
  formatFaqSchemaForHeadTag,
  formatOgImageWithFullHead,
  formatOpenGraphImage,
  formatSeoAuthor,
} from "./seo";

// Format WP page data/content
export function formatPage(data) {
  if (!data?.page) {
    return null;
  }

  if (data?.page?.seo?.canonical) {
    const lastIndex = data?.page?.seo?.canonical.length - 1;
    const lastChar = data?.page?.seo?.canonical[lastIndex];
    if (lastChar !== "/") {
      data.page.seo.canonical = `${data.page.seo.canonical}/`;
    }
  }

  const baseCanonical =
    data?.page?.seo?.opengraphUrl || data?.page?.seo?.canonical;
  const canonical = baseCanonical?.replace("/wp.", "/www.") || "";
  return (
    data?.page && {
      content: data.page.content,
      title: data.page.title,
      uri: data.page.uri,
      featuredImage: data?.page?.featuredImage?.node || null,
      rivalryTeams:
        data?.page?.rivalryMatchup?.rivalryTeams?.replace(",", "/") || "",
      author: formatAuthorDetails(data?.page?.author?.node),
      lastEditedBy: formatAuthorDetails(data?.page?.lastEditedBy?.node),
      date: data?.page?.date,
      modified: data?.page?.modified,
      seo: {
        title:
          data?.page?.seo?.title ||
          (data?.page?.title ? unescapeHTML(data.page.title) : ""),
        metaDesc: data?.page?.seo?.metaDesc,
        publishedTime: data?.page?.seo?.opengraphPublishedTime ?? "",
        modifiedTime: data?.page?.seo?.opengraphModifiedTime ?? "",
        faqSchema: data?.page?.wpFaqSchema
          ? formatFaqSchemaForHeadTag(JSON.parse(data.page.wpFaqSchema))
          : null,
        canonical: canonical,
        ogType: data?.page?.seo?.opengraphType ?? null,
        ogImage: data?.page?.seo?.opengraphImage
          ? formatOpenGraphImage(data?.page?.seo?.opengraphImage)
          : formatOgImageWithFullHead(data?.page?.seo?.fullHead) ?? null,
        schema: data?.page?.seo?.schema?.raw ?? null,
        author: formatSeoAuthor(data?.page?.author) ?? null,
        readTime: data?.page?.seo?.readingTime ?? null,
        robots:
          data?.page?.seo?.metaRobotsNoindex &&
          data?.page?.seo?.metaRobotsNofollow
            ? `${data.page.seo.metaRobotsNoindex}, ${data.page.seo.metaRobotsNofollow}`
            : null,
      },
    }
  );
}

export function formatPreloadPagesPaths(data) {
  const uris = data?.pages?.edges?.map(({ node }) => node?.uri) || [];
  const nestedPaths = [];
  const singlePaths = [];

  for (const uri of uris) {
    const cleanedUri = uri?.slice(1, -1) || "";
    const splittedUri = cleanedUri?.split("/");
    const hasNestedPaths = splittedUri?.length > 1;
    if (hasNestedPaths) {
      nestedPaths.push({
        params: {
          path: splittedUri?.[0],
          nested_path: [splittedUri?.[1]],
        },
      });
    } else {
      singlePaths.push(splittedUri?.[0]);
    }
  }
  return { nestedPaths, singlePaths };
}

export function addPagesToArchive(category, maxPages = 5) {
  const archivesWithPages = [];
  for (let i = 1; i <= maxPages; i++) {
    const params = { archive: [category] };
    if (i > 1) {
      params.archive.push("page", i.toString());
    }
    archivesWithPages.push({ params });
  }
  return archivesWithPages;
}
