import { sendGTMEvent } from "@next/third-parties/google";
import { useRouter } from "next/router";
import { useEffect } from "react";

export function useGTM(post) {
  const author =
    post?.author?.name || post?.author?.twitter || post?.author?.id;

  useEffect(() => {
    if (!author) return;
    sendGTMEvent({
      event: "pageAuthor",
      pageAuthor: author,
      author,
    });
  }, [author]);
}

export function useCleanUpGTM() {
  const router = useRouter();

  useEffect(() => {
    const handleRouteChangeStart = () => {
      // Clear the author data before the route changes
      sendGTMEvent({
        event: "pageAuthor",
        pageAuthor: "noauthor",
        author: "noauthor",
      });
    };

    router.events.on("routeChangeStart", handleRouteChangeStart);

    // Cleanup the event listener when the component unmounts
    return () => {
      router.events.off("routeChangeStart", handleRouteChangeStart);
    };
  }, [router]);
}
