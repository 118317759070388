import * as dayjs from "dayjs";

import { formatAuthorDetails } from "./author";
import {
  formatFaqSchemaForHeadTag,
  formatOpenGraphImage,
  formatSeoAuthor,
  transformHeadTagURLs,
} from "./seo";
import { unescapeHTML } from "./word";

// Helper function to safely access nested properties
const getNestedProperty = (obj, path, defaultValue = undefined) => {
  return path.reduce((acc, part) => acc && acc[part], obj) || defaultValue;
};

// Helper function to pick specific properties from an object
const pickProperties = (obj, keys) => {
  return keys.reduce((result, key) => {
    if (obj && obj.hasOwnProperty(key)) {
      result[key] = obj[key];
    }
    return result;
  }, {});
};

// Helper function to flatten an array of arrays
const flattenArray = (arr) => {
  return arr.reduce((flat, toFlatten) => {
    return flat.concat(
      Array.isArray(toFlatten) ? flattenArray(toFlatten) : toFlatten
    );
  }, []);
};

// Format WP post content / data
export function formatPost(post) {
  const category = getNestedProperty(post, [
    "postBy",
    "categories",
    "edges",
    0,
    "node",
    "slug",
  ]);

  const baseCanonical =
    post?.postBy?.seo?.opengraphUrl || post?.postBy?.seo?.canonical;
  const canonical = baseCanonical ? baseCanonical.replace("/wp.", "/www.") : "";

  const gameId = (
    getNestedProperty(post, ["postBy", "gameId", "gameId"]) || ""
  ).toLowerCase();
  const date =
    getNestedProperty(post, ["postBy", "seo", "opengraphPublishedTime"]) ||
    getNestedProperty(post, ["postBy", "date"]);
  const modified =
    getNestedProperty(post, ["postBy", "seo", "opengraphModifiedTime"]) ||
    getNestedProperty(post, ["postBy", "modified"]);
  return {
    author: formatAuthorDetails(
      getNestedProperty(post, ["postBy", "author", "node"])
    ),
    category: category || null,
    content: getNestedProperty(post, ["postBy", "content"]) || "",
    date: date || null,
    modified: modified || null,
    uri: getNestedProperty(post, ["postBy", "uri"]) || null,
    featuredImage: getNestedProperty(
      post,
      ["postBy", "featuredImage", "node"],
      null
    ),
    matchupLink: gameId ? `/${category}/matchup/${gameId}` : "",
    gameId,
    seo: {
      title:
        getNestedProperty(post, ["postBy", "seo", "title"]) ||
        unescapeHTML(getNestedProperty(post, ["postBy", "title"])) ||
        "",
      metaDesc:
        getNestedProperty(post, ["postBy", "seo", "metaDesk"]) ||
        unescapeHTML(
          getNestedProperty(post, ["postBy", "seo", "opengraphDescription"])
        ) ||
        "",
      publishedTime: post?.postBy?.seo?.opengraphPublishedTime ?? "",
      modifiedTime: post?.postBy?.seo?.opengraphModifiedTime ?? "",
      ogType: post?.postBy?.seo?.opengraphType ?? null,
      ogImage: post?.postBy?.seo?.opengraphImage
        ? formatOpenGraphImage(post?.postBy?.seo?.opengraphImage)
        : null,
      schema: post?.postBy?.seo?.schema?.raw ?? null,
      canonical,
      author: formatSeoAuthor(post?.author) ?? null,
      readTime: post?.postBy?.seo?.readingTime ?? null,
      faqSchema: getNestedProperty(post, ["postBy", "wpFaqSchema"])
        ? formatFaqSchemaForHeadTag(
            JSON.parse(getNestedProperty(post, ["postBy", "wpFaqSchema"]))
          )
        : null,
      robots:
        post?.postBy?.seo?.metaRobotsNoindex &&
        post?.postBy?.seo?.metaRobotsNofollow
          ? `${post.postBy.seo.metaRobotsNoindex}, ${post.postBy.seo.metaRobotsNofollow}`
          : null,
    },
    title: getNestedProperty(post, ["postBy", "title"]) || "",
  };
}

// Format WP post content / data for post list / archive page
export function formatPostsForList(data) {
  return (data?.posts?.edges || []).map((edge) => {
    const item = edge?.node;
    return {
      ...pickProperties(item, ["id", "date", "title", "uri"]),
      author: getNestedProperty(item, ["author", "node", "uri"])
        ? item.author.node
        : null,
      categories: flattenArray(
        (item?.categories?.edges || []).map((edge) =>
          pickProperties(edge?.node, ["name", "slug", "uri"])
        )
      ),
      featuredImage: getNestedProperty(item, ["featuredImage", "node"], ""),
    };
  });
}

// Format WP evergreen content / data for evergreen list / archive page
export function formatPagesForList(data) {
  return (data?.pages?.edges || []).map((edge) => {
    const item = edge?.node;
    return {
      ...pickProperties(item, ["id", "date", "modified", "title", "uri"]),
      author: getNestedProperty(item, ["author", "node", "uri"])
        ? item.author.node
        : null,
      featuredImage: getNestedProperty(item, ["featuredImage", "node"], ""),
    };
  });
}

// Format WP post content / data for post list / tag archive page
export function formatPostsByTagForList(data) {
  return (data?.posts?.edges || []).map((edge) => {
    const item = edge?.node;
    return {
      ...pickProperties(item, ["id", "date", "title", "uri"]),
      author: getNestedProperty(item, ["author", "node", "uri"])
        ? item.author.node
        : null,
      tags: flattenArray(
        (item?.tags?.edges || []).map((edge) =>
          pickProperties(edge?.node, ["name", "slug", "uri"])
        )
      ),
      featuredImage: getNestedProperty(item, ["featuredImage", "node"], ""),
    };
  });
}

// Format WP post content / data for matchup page
export function formatPostForMatchup(data) {
  const post = getNestedProperty(data, ["posts", "edges", 0, "node"]);
  return post
    ? {
        author: getNestedProperty(post, ["author", "node", "name"], ""),
        title: getNestedProperty(post, ["title"]),
        uri: getNestedProperty(post, ["uri"]),
        featuredImage: getNestedProperty(post, ["featuredImage", "node"]),
      }
    : null;
}

/**
 *
 * @param {dateString} postDate
 * @returns a display string with the formatted time since publication
 */
export function getPostPublishedDisplay(postDate) {
  const minutesDiff = dayjs().diff(postDate, "minute");
  const hoursDiff = dayjs().diff(postDate, "hour");
  if (minutesDiff < 60) {
    const minutesDescription = minutesDiff > 1 ? "minutes" : "minute";
    return `${minutesDiff} ${minutesDescription} ago`;
  } else if (hoursDiff < 24) {
    const hoursDescription = hoursDiff > 1 ? "hours" : "hour";
    return `${hoursDiff} ${hoursDescription} ago`;
  } else {
    return dayjs(postDate).format("ddd MMM D YYYY");
  }
}
