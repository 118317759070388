import * as dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import numeral from "numeral";

import { SOCCER_LEAGUES } from "@/config/constants";

import { getPercentages, getPicksAndPercentages } from "./games";
import { getGameStatsSummary } from "./stats";
import { colorSimilarity } from "./tools";

dayjs.extend(utc);

function getMatchupConsensus(consensus, awayTeam, homeTeam) {
  const awaySpread = Number(consensus?.spread?.away?.spread);
  const homeSpread = Number(consensus?.spread?.home?.spread);
  let favorite = { spread: "Even", team: "Pick" };
  if (awaySpread > homeSpread) {
    favorite = { spread: homeSpread, team: homeTeam?.abbr };
  }
  if (homeSpread > awaySpread) {
    favorite = { spread: awaySpread, team: awayTeam?.abbr };
  }

  const overUnder =
    consensus?.over_under?.over?.points &&
    parseFloat(consensus?.over_under?.over?.points) > 0
      ? consensus?.over_under?.over?.points
      : "n/a";

  return {
    ...favorite,
    overUnder,
  };
}

function getTeamRecord(team, league) {
  const record = `${team?.standings?.wins} - ${team?.standings?.losses}`;
  return league === "nhl" ? `${record} - ${team?.standings?.otl}` : record;
}

export function formatRivalry(game) {
  const gameKey = game?.game_key?.toLowerCase();
  const gameStartTime = dayjs(game?.start_time).local();
  return gameKey
    ? {
        away: {
          abbreviation: game?.away_team?.abbr,
          location: game?.away_team?.location_name,
          logo: game?.away_team?.logo,
          name: game?.away_team?.name,
          record: getTeamRecord(game?.away_team, game?.league),
        },
        broadcaster: game?.broadcasters?.[0]?.name || "",
        consensus: getMatchupConsensus(
          game?.odds?.consensus,
          game?.away_team,
          game?.home_team
        ),
        home: {
          abbreviation: game?.home_team?.abbr,
          location: game?.home_team?.location_name,
          logo: game?.home_team?.logo,
          name: game?.home_team?.name,
          record: getTeamRecord(game?.home_team, game?.league),
        },
        matchupLink: `/${game?.league}/matchup/${gameKey}`,
        startDateString: gameStartTime.format(),
        startDate: gameStartTime.format("MMMM D"),
        startTime: gameStartTime.format("h:mm A"),
        venue: {
          location: game?.venue?.venue_location,
          name: game?.venue?.venue_name,
        },
      }
    : null;
}

function getOddsTableData(sites, league = "") {
  const sections = { moneyLine: [], pointSpread: [], total: [] };
  if (!sites) {
    return sections;
  }
  for (const [key, site] of Object.entries(sites)) {
    const moneyLine = site?.money_line;
    const spread = site?.spread;
    const total = site?.over_under;
    if (moneyLine) {
      const moneyLineEntry = {
        site: key,
        link: site?.link,
        away: {
          primary: moneyLine?.away?.odds,
          isBest: moneyLine?.away?.best,
        },
        home: {
          primary: moneyLine?.home?.odds,
          isBest: moneyLine?.home?.best,
        },
        draw: {
          primary: moneyLine?.draw?.odds || 0,
          isBest: moneyLine?.draw?.best || false,
        },
      };
      if (!SOCCER_LEAGUES.includes(league)) {
        delete moneyLineEntry.draw;
      }
      sections.moneyLine.push(moneyLineEntry);
    }
    if (spread) {
      sections.pointSpread.push({
        site: key,
        link: site?.link,
        away: {
          primary: spread?.away?.spread,
          secondary: spread?.away?.odds,
          isBest: spread?.away?.best,
        },
        home: {
          primary: spread?.home?.spread,
          secondary: spread?.home?.odds,
          isBest: spread?.home?.best,
        },
      });
    }
    if (total) {
      sections.total.push({
        site: key,
        link: site?.link,
        away: {
          primary: total?.over?.points,
          secondary: total?.over?.odds,
          isBest: total?.over?.best,
        },
        home: {
          primary: total?.under?.points,
          secondary: total?.under?.odds,
          isBest: total?.under?.best,
        },
      });
    }
  }
  return sections;
}

function getOdds(game) {
  return {
    away: {
      logo: game?.away_team?.logo,
      team: game?.away_team?.abbr.toUpperCase(),
    },
    home: {
      logo: game?.home_team?.logo,
      team: game?.home_team?.abbr.toUpperCase(),
    },
    league: game?.league,
    ...getOddsTableData(game?.odds?.sites, game?.league),
  };
}

function getPicksSection(game, sectionKey) {
  const sites = game?.predictions?.sites ?? [];
  const sectionSites = sites.filter((site) => site?.picks?.[sectionKey]);
  const percentages = getPercentages(
    sectionSites,
    (site) => site?.picks?.[sectionKey],
    sectionKey === "over_under" ? "under" : "home"
  );

  return {
    experts: {
      away: percentages?.away,
      home: percentages?.home,
    },
    publicBet: game?.public?.[sectionKey]
      ? {
          away: `${game?.public?.[sectionKey]?.away}%`,
          home: `${game?.public?.[sectionKey]?.home}%`,
        }
      : null,
    sites: sectionSites.map((site) => ({
      pick: site?.picks?.[sectionKey]?.pick,
      name: site?.provider,
    })),
  };
}

function getPicks(game) {
  const scoreSites = Array.isArray(game?.predictions?.sites)
    ? game.predictions.sites.filter((site) => site?.is_per == true)
    : [];

  const { aPer, bPer } = getPicksAndPercentages(
    scoreSites,
    (site) => site?.predictions?.away?.score > site?.predictions?.home?.score
  );

  return {
    away: {
      logo: game?.away_team?.logo,
      team: game?.away_team?.abbr.toUpperCase(),
    },
    home: {
      logo: game?.home_team?.logo,
      team: game?.home_team?.abbr.toUpperCase(),
    },
    moneyLine: getPicksSection(game, "money_line"),
    pointSpread: getPicksSection(game, "spread"),
    total: getPicksSection(game, "over_under"),
    score: {
      experts: {
        away:
          Number(aPer)?.toFixed(0) !== "NaN"
            ? `${Number(aPer)?.toFixed(0) ?? 0}%`
            : "-",
        home:
          Number(bPer)?.toFixed(0) !== "NaN"
            ? `${Number(bPer)?.toFixed(0) ?? 0}%`
            : "-",
      },
      sites: scoreSites.map((site) => ({
        away: Number(site?.predictions?.away?.score)?.toFixed(2),
        home: Number(site?.predictions?.home?.score)?.toFixed(2),
        name: site?.provider,
      })),
    },
  };
}

function getQuickCap(game) {
  // Select winner side (away, home) Based on Odds scores from API
  const predictionsConsensus = game?.predictions?.sites ?? [];
  // Consensus %  based on predictions scores. E.g. Action Network
  const moneyLineConsensus = getPercentages(
    predictionsConsensus.filter((site) => site?.picks?.money_line),
    (site) => site?.picks?.money_line,
    "home"
  );
  const overUnderConsensus = getPercentages(
    predictionsConsensus.filter((site) => site?.picks?.over_under),
    (site) => site?.picks?.over_under,
    "under"
  );
  const spreadConsensus = getPercentages(
    predictionsConsensus.filter((site) => site?.picks?.spread),
    (site) => site?.picks?.spread,
    "home"
  );

  const homeTeamName = game?.home_team?.name || game?.home_team?.abbr;
  const awayTeamName = game?.away_team?.name || game?.away_team?.abbr;
  // Display winner team based on odds stats not prediciton E.g. Action Network
  const moneyLineTeamPick =
    game?.best_bets?.money_line.winner === "home"
      ? { team: homeTeamName, pick: "home" }
      : { team: awayTeamName, pick: "away" };
  const spreadTeamPick =
    game?.best_bets?.spread.winner === "home"
      ? { team: homeTeamName, pick: "home" }
      : { team: awayTeamName, pick: "away" };

  return (
    game?.best_bets && {
      moneyLine: {
        consensus:
          game?.best_bets?.money_line?.winner === "home"
            ? `${moneyLineConsensus?.home} consensus`
            : `${moneyLineConsensus?.away} consensus`,
        pick: `${moneyLineTeamPick?.team || ""} win`,
        pickLogo:
          game?.best_bets?.money_line?.winner === "home"
            ? game?.home_team?.logo
            : game?.away_team?.logo,
        primary: game?.best_bets?.money_line?.odds || "n/a",
        site: game?.best_bets?.money_line?.site,
        siteLink: game?.best_bets?.money_line?.link,
        title: "Moneyline",
      },
      overUnder: {
        consensus:
          game?.best_bets?.over_under?.winner === "home"
            ? `${overUnderConsensus?.home} consensus`
            : `${overUnderConsensus?.away} consensus`,
        pick:
          game?.best_bets?.over_under?.winner === "under" ? "Under" : "Over",
        pickIcon:
          overUnderConsensus?.pick === "home"
            ? "mr-2 trend trend-under"
            : "mr-2 trend trend-over",
        primary: game?.best_bets?.over_under?.points || "n/a",
        secondary: game?.best_bets?.over_under?.odds || "n/a",
        site: game?.best_bets?.over_under?.site,
        siteLink: game?.best_bets?.over_under?.link,
        title: "Total",
      },
      spread: {
        consensus:
          game?.best_bets?.spread?.winner === "home"
            ? `${spreadConsensus?.home} consensus`
            : `${spreadConsensus?.away} consensus`,
        pick: `${spreadTeamPick?.team || ""} cover`,
        pickLogo:
          game?.best_bets?.spread?.winner === "home"
            ? game?.home_team?.logo
            : game?.away_team?.logo,
        primary: game?.best_bets?.spread?.spread || "n/a",
        secondary: game?.best_bets?.spread?.odds || "n/a",
        site: game?.best_bets?.spread?.site,
        siteLink: game?.best_bets?.spread?.link,
        title: "Point Spread",
      },
    }
  );
}

function getTeamTrends(team) {
  const teamName = team?.abbr;
  const trends = team?.record?.trends;

  if (!trends || !Array.isArray(trends)) {
    return [];
  }

  return trends.map((game) => {
    const isTeamAway = teamName === game?.away_team;
    return {
      key: game?.game_key,
      opponent: `${isTeamAway ? "@" : "vs"} ${
        isTeamAway ? game?.home_team : game?.away_team
      }`,
      overUnder: {
        result: game?.over_under?.result,
        value: game?.over_under?.over_under,
      },
      score: {
        result: game?.overall?.result,
        value: `${game?.overall?.away_score} - ${game?.overall?.home_score}`,
      },
      spread: {
        result: game?.spread?.result,
        value: game?.spread?.spread,
      },
    };
  });
}

function getMatchupTrends(game) {
  return {
    away: {
      logo: game?.away_team?.logo,
      team: game?.away_team?.abbr,
      trends: getTeamTrends(game?.away_team),
      league: game?.league,
    },
    home: {
      logo: game?.home_team?.logo,
      team: game?.home_team?.abbr,
      trends: getTeamTrends(game?.home_team),
      league: game?.league,
    },
  };
}

function getTeamNoLineRecord(team, league, venue, color) {
  const overallRecord = team?.record?.noline?.overall;
  const record = team?.record?.noline?.[venue];
  return {
    color: color,
    position: numeral(overallRecord?.pos).format("o"),
    overallRecord:
      league === "nhl"
        ? `${overallRecord?.wins} - ${overallRecord?.losses} - ${overallRecord?.otl}`
        : `${overallRecord?.wins} - ${overallRecord?.losses}`,
    record:
      league === "nhl"
        ? `${record?.wins} - ${record?.losses} - ${record?.otl}`
        : `${record?.wins} - ${record?.losses}`,
  };
}

function getTeamOverUnderRecord(team, venue, color) {
  const overallRecord = team?.record?.over_under?.overall;
  const record = team?.record?.over_under?.[venue];
  return {
    color: color,
    position: numeral(overallRecord?.pos).format("o"),
    overallRecord: `${overallRecord?.over} - ${overallRecord?.under} - ${overallRecord?.push}`,
    record: `${record?.over} - ${record?.under} - ${record?.push}`,
  };
}

function getTeamSpreadRecord(team, venue, color) {
  const overallRecord = team?.record?.spread?.overall;
  const record = team?.record?.spread?.[venue];
  return {
    color: color,
    position: numeral(overallRecord?.pos).format("o"),
    overallRecord: `${overallRecord?.wins} - ${overallRecord?.losses} - ${overallRecord?.push}`,
    record: `${record?.wins} - ${record?.losses} - ${record?.push}`,
  };
}

function getAwayStatPercentage(awayTeam, homeTeam, stat) {
  const away = awayTeam?.record?.[stat]?.overall;
  const home = homeTeam?.record?.[stat]?.overall;
  let percent = 0;
  if (away?.per + home?.per > 0) {
    percent = away?.per / (away?.per + home?.per);
  }
  return (percent * 100)?.toFixed(0);
}

// Format matchup stats data
export function formatMatchupStats(game, stats) {
  const awayTeam = game?.away_team;
  const homeTeam = game?.home_team;
  const [homeColor, awayColor] = colorSimilarity(homeTeam, awayTeam);

  return {
    away: {
      color: awayColor,
      logo: awayTeam?.logo,
      team: awayTeam?.abbr,
    },
    home: {
      color: homeColor,
      logo: homeTeam?.logo,
      team: homeTeam?.abbr,
    },
    league: game?.league,
    // Betting Records
    bettingRecord: {
      noline: {
        title: "Wins",
        subtitle: "Away/Home",
        awayPercentage: getAwayStatPercentage(awayTeam, homeTeam, "noline"),
        away: getTeamNoLineRecord(awayTeam, game?.league, "away", awayColor),
        home: getTeamNoLineRecord(homeTeam, game?.league, "home", homeColor),
      },
      spread: {
        title: "ATS",
        subtitle: "Away/Home",
        awayPercentage: getAwayStatPercentage(awayTeam, homeTeam, "spread"),
        away: getTeamSpreadRecord(awayTeam, "away", awayColor),
        home: getTeamSpreadRecord(homeTeam, "home", homeColor),
      },
      overUnder: {
        title: "Ov / Un",
        subtitle: "Away/Home",
        awayPercentage: getAwayStatPercentage(awayTeam, homeTeam, "over_under"),
        away: getTeamOverUnderRecord(awayTeam, "away", awayColor),
        home: getTeamOverUnderRecord(homeTeam, "home", homeColor),
      },
    },
    // Team Stats
    stats,
    // Stats Summary
    summary: getGameStatsSummary(game),
  };
}

// Format matchup data for matchup page
export function formatMatchup(game, teamStats) {
  return {
    id: game?.game_key,
    broadcaster: game?.broadcasters?.[0]?.name || "",
    league: game?.league?.toUpperCase(),
    week: game?.week || "",
    home: {
      id: game?.home_team?.team_id,
      fullName: game?.home_team?.full_name,
      logo: game?.home_team?.logo,
    },
    away: {
      id: game?.away_team?.team_id,
      fullName: game?.away_team?.full_name,
      logo: game?.away_team?.logo,
    },
    gameStatus: game?.game_status,
    // Matchup Odds table data
    odds: getOdds(game),
    // Matchup Picks table data
    picks: getPicks(game),
    quickcap: getQuickCap(game) ?? [],
    seo: {
      canonical: `https://www.bettingnews.com/${game?.league?.toLowerCase()}/matchup/${game?.game_key?.toLowerCase()}/`,
      title: `${game?.away_team?.full_name} vs ${game?.home_team?.full_name} - ${dayjs(
        game?.start_time
      ).format(
        "MMMM D, YYYY"
      )} - Picks, Odds & Stats - ${game?.league?.toUpperCase()}`,
    },
    startTime: game?.start_time,
    // Matchup stats blocks
    stats: formatMatchupStats(game, teamStats),
    title: `${game?.away_team?.full_name} vs ${game?.home_team?.full_name} Picks`,
    // Trends / Last 5 Games
    trends: getMatchupTrends(game),
    venue: game?.venue && {
      location:
        game?.venue?.venue_location === "n/a"
          ? ""
          : game?.venue?.venue_location,
      name: game?.venue?.venue_name === "n/a" ? "" : game?.venue?.venue_name,
    },
    winnerSide: game?.winner_side ?? "home",
    winnerName: game?.winner_name ?? game?.home_team?.full_name,
  };
}
