export async function fetchAPICommon(url, options, type, extraData) {
  try {
    const res = await fetch(url, options);
    if (res.ok) {
      const json = await res.json();
      if (json.errors) {
        throw new Error("Failed to fetch API");
      }
      if (type === "standings") return { [extraData?.season]: json.data };
      if (url?.includes("health-check")) return json;

      return json.data;
    }
    return {};
  } catch (error) {
    // eslint-disable-next-line no-console
    console.error(
      `All attempts failed. Last error: ${error.message}. url: ${url}`
    );
    return {};
  }
}
